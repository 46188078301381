import React from 'react';
import Layout from '../components/Layout';
import { Link } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  infoContainerText: {
    maxWidth: theme.spacing(1) * 100,
    textAlign: "start"
  },
  infoContainerLink: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
}));

const NotFoundPage = () => {
  const classes = useStyles();

  return (
    <Layout pageName="Erreur">
      <div className={classes.infoContainerText}>
        <Typography variant="h1">Mmmm, cette page n&#39;existe pas...</Typography>
        <p>Retourner sur la page d'
          <Link 
            to="/" 
            cover 
            direction="down"
            bg="#000000" 
            className={classes.infoContainerLink}
          >
          Accueil
          </Link>
        </p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;